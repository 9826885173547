import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../Contexts/ThemeContext";
import Product from "../../Components/Shared/Product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faList } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Helmet } from "react-helmet-async";

const CategoryPage = () => {
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { category } = useParams();
  const [list, setList] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/categoryProductCount?name=${category}`
    )
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
    fetch(`https://flormar-web-server-nayem55-flormar-bd.vercel.app/categories`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      });
  }, [category]);

  useEffect(() => {
    setCategoryProducts([]);
    setLoading(true);
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/getProductsByCategories?name=${
        category === "eyes"
          ? "eye"
          : category === "nails"
          ? "nail polish"
          : category
      }&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCategoryProducts(data);
        setLoading(false);
        // Create an array to store the item data for the view_item_list event
        const viewItemListData = [];

        data.slice(0, 6).forEach((product) => {
          const color = product?.variations?.find(
            (v) => v?.product_slug === product?.slug
          )?.color;
          viewItemListData.push({
            item_id: product._id,
            item_name: product.name,
            item_category: product.categories[0]?.name || "",
            item_category2: product.categories[1]?.name || "",
            item_category3: product.categories[2]?.name || "",
            price: product.regular_price,
            item_variant: color ? color : "",
          });
        });

        // Push the view_item_list event data to the DataLayer
        window.dataLayer.push({
          event: "view_item_list",
          ecommerce: {
            item_list_name: category,
            items: viewItemListData,
          },
        });
      });
    window.scrollTo(0, 0);
  }, [category, page]);

  const meta_description = categories.find(
    (item) => item?.name?.toLowerCase() == category?.toLowerCase()
  )?.meta_description;
  const meta_title = categories.find(
    (item) => item?.name?.toLowerCase() == category?.toLowerCase()
  )?.meta_title;

  return (
    <div className="2xl:w-[65%] lg:w-[75%] w-[95%] mx-auto mb-20">
      <Helmet>
        <title>{`${meta_title || category} - Flormar Bangladesh`}</title>
        <meta name="description" content={meta_description} />
        <link
          rel="canonical"
          href={`https://flormarbd.com/product-category/${category}`}
        />
        {/* Add JSON-LD structured data for the category */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "ItemList",
              "name": "${meta_title} - Flormar Bangladesh",
              "description": "${meta_description}",
              "itemListElement": [
                ${categoryProducts
                  .map(
                    (product, index) => `
                  {
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "url": "https://flormarbd.com/product/${product?.slug}"
                  }
                `
                  )
                  .join(",")}
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="my-10 w-[90%] 2xl:w-[full lg:w-full mx-auto">
        <p className="text-[14px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          <h1 className="inline">{meta_title}</h1>
        </p>
      </div>
      <p
        className={`w-[90%] 2xl:w-full lg:w-full mx-auto ${
          category?.includes("top 10") ? "text-center" : ""
        } font-bold my-10 text-[22px]`}
      >
        {category?.toUpperCase()}
      </p>

      <div className="hidden lg:flex items-center mb-10">
        <button onClick={() => setList(false)}>
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/sf-regular-filled/48/grid.png"
            alt="grid"
            className="inline mt-[-4px]"
          />
          Grid
        </button>
        <button onClick={() => setList(true)}>
          <FontAwesomeIcon className="mx-2" icon={faList}></FontAwesomeIcon>List
        </button>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-[50vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {list ? (
        <div className="list-container grid gap-6 grid-cols-1">
          {categoryProducts?.map((product) => (
            <Product key={product._id} list={list} product={product}></Product>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-y-8 lg:gap-6 lg:grid-cols-4">
          {categoryProducts?.map((product) => (
            <Product key={product._id} product={product}></Product>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default CategoryPage;
