import React, { useEffect, useState } from "react";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { TailSpin } from "react-loader-spinner";
import google from "../../Images/google.jpg";
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";

const Login = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpUser, setOtpUser] = useState({});
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [ph, setPh] = useState("");
  const [name, setName] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [signInWithGoogle, user1, loading1, error1] = useSignInWithGoogle(auth);

  useEffect(() => {
    if (user1) {
      toast.success("Logged in successfully");
      const email = user1?.user?.email;

      fetch(
        `https://flormar-web-server-nayem55-flormar-bd.vercel.app/postGoogleUser/${email}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            name: user1?.user?.displayName,
            phone: "",
            email: user1?.user?.email,
            role: "customer",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => console.log(data));
      navigate("/");
    }
  }, [user1]);
  
  useEffect(() => {
    // Check localStorage for user data and redirect if found
    if (otpUser?.phone) {
      navigate("/"); // Redirect to home
    }
  }, [otpUser]);

  useEffect(() => {
    if (ph.length === 11) {
      const phoneNumber = "+88" + ph;
      fetch(
        `https://flormar-web-server-nayem55-flormar-bd.vercel.app/getUser/${phoneNumber}`
      )
        .then((res) => res.json())
        .then((data) => setCustomer(data));
    }
  }, [ph]);

  const generateOtp = () => {
    const otpCode = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a 6-digit OTP
    setGeneratedOtp(otpCode);
    return otpCode;
  };

  const onSignInSubmit = async () => {
    setLoading(true);
    const phoneNumber = "+88" + ph;
    const otpCode = generateOtp();

    // Use Alpha Net SMS API to send the OTP with fetch
    const formData = new URLSearchParams({
      api_key: "jgBL1xyF0i7NK58kfZSD2y2fU8zUaeCRASDQovj7",
      msg: `${otpCode} is your OTP for flormarbd.com`,
      to: phoneNumber,
    });

    try {
      const response = await fetch("https://api.sms.net.bd/sendsms", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to send OTP");
      }

      setLoading(false);
      setShowOTP(true);
      toast.success("OTP sent to your number");
    } catch (error) {
      toast.error("Failed to send OTP, please try again");
      setLoading(false);
    }
  };

  const onOtpVerify = () => {
    const phoneNumber = "+88" + ph;
    const loggedUser = {
      name: name,
      phone: phoneNumber,
      email: "",
      role: "customer",
    };
    setLoading(true);
    if (otp === generatedOtp) {
      toast.success("Logged In Successfully");
      setOtpUser(loggedUser);
      localStorage.setItem("user",JSON.stringify(loggedUser));
      setLoading(false);

      if (!customer?.phone) {
        fetch(
          "https://flormar-web-server-nayem55-flormar-bd.vercel.app/postUser",
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(loggedUser),
          }
        )
          .then((res) => res.json())
          .then((data) => console.log(data));
      } else {
        console.log("Customer exists");
      }
    } else {
      toast.error("Invalid OTP");
      setLoading(false);
    }
  };

  return (
    <div className="2xl:w-[65%] lg:w-[75%] w-[90%] mx-auto mb-20">
      <div id="recaptcha-container"></div>
      <div className="my-10">
        <p className="text-[12px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          Account
        </p>
        <p className="text-xl my-4 font-bold">CUSTOMER LOGIN</p>
      </div>

      {showOTP ? (
        <div className="mb-4 flex flex-col">
          <p className="text-[14px] font-semibold mb-2">Enter OTP</p>
          <input
            type="number"
            placeholder="Enter OTP"
            className="border px-3 border-secondary border-opacity-50 w-[80%] lg:w-[30vw] h-[40px]"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          {loading ? (
            <button className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center">
              <TailSpin
                height="25"
                width="25"
                color="#fff"
                ariaLabel="tail-spin-loading"
              />
            </button>
          ) : (
            <button
              onClick={onOtpVerify}
              className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center hover:bg-secondary ease-in-out duration-200"
            >
              Login
            </button>
          )}
        </div>
      ) : (
        <div className="mb-4 flex flex-col">
          <p className="text-[14px] font-semibold mb-2">Full Name</p>
          <input
            type="text"
            placeholder="Enter your name"
            required
            className="border px-3 border-secondary border-opacity-50 mb-4 w-[80%] lg:w-[30vw] h-[40px]"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <p className="text-[14px] font-semibold mb-2">Phone Number</p>
          <input
            type="number"
            required
            placeholder="Enter your phone number"
            className="border px-3 border-secondary border-opacity-50 w-[80%] lg:w-[30vw] h-[40px]"
            value={ph}
            onChange={(e) => setPh(e.target.value)}
          />
          {loading ? (
            <button className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center">
              <TailSpin
                height="25"
                width="25"
                color="#fff"
                ariaLabel="tail-spin-loading"
              />
            </button>
          ) : (
            <button
              onClick={onSignInSubmit}
              className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center hover:bg-secondary ease-in-out duration-200"
            >
              Send OTP
            </button>
          )}
          <hr className="mt-10 w-[80%] lg:w-[30vw]" />
          <p className="text-center w-[80%] lg:w-[30vw] mt-2">OR</p>
          <div className="flex gap-4">
            <button
              onClick={() => signInWithGoogle()}
              className="bg-[#F5F5F5] w-[80%] lg:w-[30vw] mt-2 py-2 rounded flex justify-center items-center gap-4"
            >
              <img className="w-6" src={google} alt="" />
              Login With Google
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
