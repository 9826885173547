import React from "react";
import "./HomeBanner.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import banner1 from "../../Images/Mono Eyeshadow & Extreme Tattoo.webp";
import banner2 from "../../Images/mascara-1-65e41f843889c.webp";
import banner3 from "../../Images/cover.jpg";

const HomeBanner = () => {
  const { searchText, setSearchText } = useContext(ThemeContext);

  return (
    <div className="homeBanner pt-10 px-4 2xl:px-[10%] lg:px-[8%] mx-auto">
      <div className="flex justify-center gap-4 lg:gap-10 w-[100%]">
        <Link
          className="w-[50%]"
          to={`/search/flormar extreme tattoo gel pencil`}
        >
          <img src={banner1} alt="eye pencil" className="rounded-2xl w-[100%]" />
        </Link>
        <Link to="/mascara" className="w-[50%]">
          <img src={banner2} alt="mascara" className="rounded-2xl w-[100%]" />
        </Link>
      </div>
      <div className="flex justify-center my-4 lg:my-10">
        <Link to="product-category/lips">
          <img src={banner3} alt="Best makeup shop" className="rounded-2xl" />
        </Link>
      </div>
    </div>
  );
};

export default HomeBanner;
