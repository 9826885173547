import React from "react";
import img from "../Images/location.png";

const Malls = () => {
  return (
    <div>
      <div className="bg-[#F9F9F9] p-6 sm:p-16">
        <p className="text-2xl sm:text-5xl font bold text-center">
          Our Shop Locations
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-16 py-10 gap-6 ">
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Bashundhara+City+Parking+Entrance,+Dhaka/@23.776121,90.3499735,13z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200 ">
            <p className="text-xl text-accent font-bold text-center">
              Bashundhara City Shopping Mall
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 46 , Level 03 , Block - B , Panthapath , Dhaka-1205
            </p>
            <p className="mt-6 font-bold"> 01965-006000</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Shimanto+Shambhar+Mosque,+Dhaka/@23.7475722,90.3724548,15z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              SHIMANTO SQUARE
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 101-102 , 1st Floor , Dhanmondi - 2 , Beside BDR Pilkhana
              Gate , Dhaka-1209.
            </p>
            <p className="mt-6 font-bold"> 01404-403713</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Shaptak+Marjan,+Dhaka/@23.7476108,90.3724548,15z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              SHAPTAK SQUARE
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 05 , Ground Floor , Road# 27 , Dhanmondi , Dhaka-1205.
            </p>
            <p className="mt-6 font-bold"> 01404-403868</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Tajmahal+Road,+Dhaka/@23.7476494,90.3724548,15z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              TAJMAHAL ROAD, MOHAMMADPUR
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 15 , Mohammadpur Girls School Market , Tajmahal Road ,
              Dhaka-1207.
            </p>
            <p className="mt-6 font-bold"> 01404-403864</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(+Adabor+)/@23.7693284,90.3533158,17z/data=!3m2!4b1!5s0x3755c0a22cf2fc31:0x2af35ea46df7de4d!4m6!3m5!1s0x3755c1180bba23b7:0x668cf92a1cb09900!8m2!3d23.7693236!4d90.3581867!16s%2Fg%2F11slg0jyh8?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              ADABAR, MOHAMMADPUR
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 28 , Shahbuddin Plaza , Adabor , Mohammadpur , Dhaka-1207.
            </p>
            <p className="mt-6 font-bold"> 01313-995454</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/FLORMAR/@23.7923868,90.4132569,17z/data=!3m2!4b1!5s0x3755cc78b2afa60d:0x403f978d6e797938!4m6!3m5!1s0x3755c7d8c0fd5351:0xc5f9cac1a785af9b!8m2!3d23.7923819!4d90.4158318!16s%2Fg%2F11j2c9v8_l?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              PINK CITY
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 1/1 , Ground Floor , Gulshan - 2 , Dhaka-1212.
            </p>
            <p className="mt-6 font-bold"> 01930-04000</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Banani,+Dhaka/@23.7858951,90.4057574,16z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">BANANI</p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              House 70/D , Block - D , Road# 11 , Banani , Dhaka-1213.
            </p>
            <p className="mt-6 font-bold"> 01941-007000</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(+Jamuna+Future+Park+)/@23.8133749,90.4192885,17z/data=!3m2!4b1!5s0x3755c64d15cb6a85:0x62398e338e585efb!4m6!3m5!1s0x3755c767129df207:0x96167402b51abc1e!8m2!3d23.8133701!4d90.4241594!16s%2Fg%2F11rvjnsq4z?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              JAMUNA FUTURE PARK (JFP)
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# GA-035A , Ground Floor , Near West Court , Dhaka-1229.
            </p>
            <p className="mt-6 font-bold">01404-403796</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(+Baily+Road+)/@23.7417084,90.4079589,17z/data=!3m1!4b1!4m6!3m5!1s0x3755b93afd555a1d:0x2e894faf12017e1c!8m2!3d23.7417035!4d90.4105338!16s%2Fg%2F11q56xqv65?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              BAILY ROAD
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              <span className="font-extrabold text-lg">
                {" "}
                Capital Siraj Center{" "}
              </span>
              , <br />
              Shop# 19 , 1st Floor , Baily Road , Dhaka-1000.
            </p>
            <p className="mt-6 font-bold"> 01404-403733</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(+Baily+Road+)/@23.7417084,90.4079589,17z/data=!3m1!4b1!4m6!3m5!1s0x3755b93afd555a1d:0x2e894faf12017e1c!8m2!3d23.7417035!4d90.4105338!16s%2Fg%2F11q56xqv65?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              BAILY ROAD
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              <span className="font-extrabold text-lg">
                {" "}
                Capital Siraj Center{" "}
              </span>
              ,
              <br /> Shop# 40 , Ground Floor , Baily Road , Dhaka-1000.
            </p>
            <p className="mt-6 font-bold"> 01404403327 </p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>

        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Khilgaon,+Dhaka/@23.776121,90.3499735,13z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              KHILGAON
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Block C , House 929 , Taltola , Khilgaon , Dhaka-1219
            </p>
            <p className="mt-6 font-bold">01404-403929 </p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>

        <a
          className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200"
          target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(Uttara)/@23.8068681,90.3087722,12z/data=!4m10!1m2!2m1!1sFlormar+near+Uttara,+Dhaka!3m6!1s0x3755c512ccd71b3b:0xd031d2ddf2038cc0!8m2!3d23.8641578!4d90.3993211!15sChpGbG9ybWFyIG5lYXIgVXR0YXJhLCBEaGFrYSIDiAEBWhsiGWZsb3JtYXIgbmVhciB1dHRhcmEgZGhha2GSAQ9jb3NtZXRpY3Nfc3RvcmXgAQA!16s%2Fg%2F11l5f48j53?entry=ttu"
        >
          <div className="flex flex-col items-center">
            <p className="text-xl text-accent font-bold text-center">UTTARA</p>
            <p className="text-[#AA507A] font-bold text-center">
              <span className="font-extrabold text-lg">Nigar Plaza, </span>
              <br /> Shop# G-12 , Sector 03 , Rd 02 , Uttara , Dhaka-1230
            </p>
            <p className="mt-6 font-bold"> 01404403329</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+CTG+Mono+Outlet/@22.3617542,91.8116471,17z/data=!3m1!4b1!4m6!3m5!1s0x30acd9cc359755c1:0x4bd857ab4b6cfa87!8m2!3d22.3617493!4d91.816518!16s%2Fg%2F11k2b2qwh1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              CHATTOGRAM
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 204 , 2nd Floor , Finlay Square , 2nd Gate , Chattogram-4000
            </p>
            <p className="mt-6 font-bold"> 01928-005000</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">WARI</p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              15/2 Rankin Street Wari Dhaka - 1203
            </p>
            <p className="mt-6 font-bold">01404403374</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(Mirpur+12)/@23.8279667,90.361291,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c1c4139c2f95:0xd7e34cab407268ac!8m2!3d23.8279618!4d90.3638659!16s%2Fg%2F11vqll_sm8?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">MIRPUR</p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 05 , Ground Floor , Noor Islam Mollah Avenue , 5 No Sujat
              Nagar , Pallabi , Mirpur-12
            </p>
            <p className="mt-6 font-bold">01404403332</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              Bashundhara City Shopping Mall
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 93 , Block C , Level 1 , Panthapath , Dhaka-1205
            </p>
            <p className="mt-6 font-bold">01404-403380</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Malls;
