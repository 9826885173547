import React from "react";
import lgBanner3 from "../Images/student-offer-pc.png";
import lgBanner2 from "../Images/clearance banner-pc.png";
import lgBanner1 from "../Images/full-color-Nail-Polish-slider-buy-1-get-2.webp";
import mbBanner1 from "../Images/full-color-mobile-Nail-Polish-slider-buy-1-get-2-2.webp";
import mbBanner2 from "../Images/clearance banner.png";
import mbBanner3 from "../Images/student-offer-mb.png";
import { Link } from "react-router-dom";
import ProductSlider from "../Components/ProductSlider/ProductSlider";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../Contexts/ThemeContext";

const Campaign = () => {
  const banner01 = window.innerWidth > 992 ? lgBanner1 : mbBanner1;
  const banner02 = window.innerWidth > 992 ? lgBanner2 : mbBanner2;
  const banner03 = window.innerWidth > 992 ? lgBanner3 : mbBanner3;
  const [offerProducts, setOfferProducts] = useState([]);
  const [offer24Products, setOffer24Products] = useState([]);
  const [bogoProducts, setBogoProducts] = useState([]);
  const [offer50Products, setOffer50Products] = useState([]);
  const { products, setSearchText, searchText } = useContext(ThemeContext);
  const bridalOffer = products
    ?.filter((item) => item.on_sale == 0)
    .slice(150, 180);

  useEffect(() => {
    fetch(
      "https://server.flormarbd.com/getProductsByTags?name=70 clearance"
    )
      .then((res) => res.json())
      .then((data) => {
        setOfferProducts(data);
      });
    fetch(
      "https://flormar-web-server-nayem55-flormar-bd.vercel.app/getProductsByTags?name=victory july"
    )
      .then((res) => res.json())
      .then((data) => {
        setOffer24Products(data);
      });
    fetch(
      "https://flormar-web-server-nayem55-flormar-bd.vercel.app/searchProduct/full color"
    )
      .then((res) => res.json())
      .then((data) => {
        setBogoProducts(data);
      });

    // fetch(
    //   `https://flormar-web-server-nayem55-flormar-bd.vercel.app/shop?page=${1}`
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setOffer24Products(data);
    //   });
  }, []);

  return (
    <div className="py-[6%] flex flex-col gap-16">
      <div>
        <Link to="/shop" className="w-[80%]">
          <img
            className="mx-auto w-[95%] sm:w-[80%] "
            src={banner03}
            alt=""
          ></img>
        </Link>
        <ProductSlider swiperProducts={offer24Products}></ProductSlider>
      </div>
      <div>
        <Link to="/clearance-offer" className="w-[80%]">
          <img
            className="mx-auto w-[95%] sm:w-[80%] "
            src={banner02}
            alt=""
          ></img>
        </Link>
        <ProductSlider swiperProducts={offerProducts}></ProductSlider>
      </div>
      <div>
        <Link to={`/search/full color`} className="w-[80%]">
          <img
            className="mx-auto w-[95%] sm:w-[80%]"
            src={banner01}
            alt="full color nail enamel"
          ></img>
        </Link>
        <ProductSlider swiperProducts={bogoProducts}></ProductSlider>
      </div>

      {/* .............combo offers............. */}
      {/* <div className="w-[90%] sm:w-[80%] mx-auto mb-20">
      <div className="grid grid-cols-1 gap-y-8 lg:gap-6 lg:grid-cols-3">
        <Link to="/combo/combo1">
          <div className="h-[550px] rounded flex flex-col justify-center items-center shadow-lg hover:shadow-xl hover:shadow-accent ease-in-out duration-200 shadow-accent">
            <img className="h-[450px]" src={combo1} alt="" />
            <p className="font-bold text-xl mt-4">TK. 1899</p>
          </div>
        </Link>
        <Link to="/combo/combo2">
          <div className="h-[550px] rounded flex flex-col justify-center items-center shadow-lg hover:shadow-xl hover:shadow-accent ease-in-out duration-200 shadow-accent">
            <img className="h-[450px]" src={combo2} alt="" />
            <p className="font-bold text-xl mt-4">TK. 2999</p>
          </div>
        </Link>
        <Link to="/combo/combo3">
          <div className="h-[550px] rounded flex flex-col justify-center items-center shadow-lg hover:shadow-xl hover:shadow-accent ease-in-out duration-200 shadow-accent">
            <img className="h-[450px]" src={combo3} alt="" />
            <p className="font-bold text-xl mt-4">TK. 3999</p>
          </div>
        </Link>
      </div>
    </div> */}

      {/* .............Bogo offers............. */}
      {/* <div className="w-[90%] sm:w-[80%] mx-auto mb-20">
      <div className="grid grid-cols-1 gap-y-8 lg:gap-6 lg:grid-cols-3">
        <Link to="/bogo1">
          <div className="h-[550px] rounded flex flex-col justify-center items-center shadow-lg hover:shadow-xl hover:shadow-accent ease-in-out duration-200 shadow-accent">
            <img className="h-[450px]" src={bogo1} alt="" />
            <p className="font-bold text-xl mt-4">TK. 1600</p>
          </div>
        </Link>
        <Link to="/bogo2">
          <div className="h-[550px] rounded flex flex-col justify-center items-center shadow-lg hover:shadow-xl hover:shadow-accent ease-in-out duration-200 shadow-accent">
            <img className="h-[450px]" src={bogo2} alt="" />
            <p className="font-bold text-xl mt-4">TK. 2280</p>
          </div>
        </Link>
        <Link to="/bogo3">
          <div className="h-[550px] rounded flex flex-col justify-center items-center shadow-lg hover:shadow-xl hover:shadow-accent ease-in-out duration-200 shadow-accent">
            <img className="h-[450px]" src={bogo3} alt="" />
            <p className="font-bold text-xl mt-4">TK. 1980</p>
          </div>
        </Link>
      </div>
    </div> */}
    </div>
  );
};

export default Campaign;
