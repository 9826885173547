import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../Contexts/ThemeContext";

const Sitemap = () => {
  const { products } = useContext(ThemeContext);
  const [urls, setUrls] = useState([]);
  console.log(urls.length);
  useEffect(() => {
    fetch(`https://flormar-web-server-nayem55-flormar-bd.vercel.app/sitemap`)
      .then((res) => res.json())
      .then((data) => {
        setUrls(data);
      });
  }, []);

  return (
    <div>
      {urls.map((product) => (
        <p>
          {`<url>`}
          <br></br>
          {`<loc>`}https://flormarbd.com/product/{product.slug}
          {`</loc>`}
          <br></br>
          {`</url>`}
        </p>
      ))}
    </div>
  );
};

export default Sitemap;

// nail polish 40
